import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { AtiraTabs } from '../../../components/AtiraTabs';
import { Modal } from '../../../components/modal/Modal';
import { EntriesExportTab } from './EntriesExportTab';
import { EntriesImportTab } from './EntriesImportTab';

const StyledTabs = styled(AtiraTabs)`
  .ant-tabs-nav-wrap {
    justify-content: center !important;
  }

  .ant-tabs-nav-list {
    width: 100%;
  }

  .ant-tabs-tab {
    flex-grow: 1;
    justify-content: center;
  }

  .ant-tabs-nav::before {
    display: none !important;
  }
`;

interface ExportImportEntriesModalProps {
  isOpen: boolean;
  onClose: VoidFunction;
}

export const ExportImportEntriesModal: React.FC<
  ExportImportEntriesModalProps
> = ({ isOpen = false, onClose }) => {
  const { t } = useTranslation();

  const items = [
    { key: '1', label: t('common.import'), children: <EntriesImportTab /> },
    { key: '2', label: t('common.export'), children: <EntriesExportTab /> },
  ];

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      title={t('entries.exim.modal.title')}
    >
      <StyledTabs items={items} />
    </Modal>
  );
};
