import { faArrowRightArrowLeft } from '@fortawesome/free-solid-svg-icons/faArrowRightArrowLeft';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import partition from 'lodash/partition';
import { useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { AtiraIcon } from '../../components/AtiraIcon';
import { DropDown } from '../../components/DropDown';
import { Flex } from '../../components/Flex';
import { Text } from '../../components/Text';
import { CreateFormDto } from '../../model/form/dto/CreateFormDto';
import { EntryFieldsDefaults } from '../../model/form/types/EntryFieldsDefaults.enum';
import { InputTypes } from '../../model/form/types/InputTypes.enum';
import { entrySliceSelectors } from '../../redux/entry/entry.selector';
import { useAppSelector } from '../../redux/store';

export const FormCreateIntegrateCustomFields = () => {
  const { control } = useFormContext<CreateFormDto>();
  const { fields: _fields, replace } = useFieldArray({
    control,
    name: 'inputs',
  });
  const [[email], fields] = partition(
    _fields,
    (f) => (f as any).custom !== true && f.type === InputTypes.EMAIL,
  );

  const { t } = useTranslation();

  const tableSettings = useAppSelector(
    entrySliceSelectors.selectUserEntryTableSettings,
  )!;

  const allUserAvailableFields = tableSettings.columns
    .filter(
      (c) =>
        c.field !== EntryFieldsDefaults.EMAIL &&
        Object.values(EntryFieldsDefaults).includes(
          c.field as EntryFieldsDefaults,
        ),
    )
    .concat(tableSettings.columns.filter((c) => c.custom));
  const [userAvailableFields, setUserAvailableFields] = useState(
    allUserAvailableFields,
  );

  const customFields = fields.filter(
    (f: any) => f.custom === true,
  ) as unknown as CreateFormDto['inputs'];

  const onIntegrateInput = (f: (typeof fields)[number], fieldName: string) => {
    const newInputs = _fields.map((input) => {
      if (input.id === f.id) {
        return { ...input, name: fieldName };
      }

      return input;
    });

    replace(newInputs);
    setUserAvailableFields(
      allUserAvailableFields.filter((f) => f.field !== fieldName),
    );
  };

  if (!customFields.length) {
    return (
      <Flex
        flexDirection="column"
        gap="m"
        justifyContent="center"
        alignItems="center"
      >
        <AtiraIcon color="main" icon={faCheck} size="3x" />
        <Text>{t('forms.create.step.4.no_custom_fields')}</Text>
      </Flex>
    );
  }

  return (
    <Flex flexDirection="column" alignItems="center" gap="m">
      <Text>{t('forms.create.step.4.has_custom_fields')}</Text>

      <Flex gap="xl" alignItems="center">
        <DropDown
          title={email.label}
          containerStyle={{ width: '10rem' }}
          onChange={() => {}}
          defaultValue={{
            label: email.label,
            value: email.label,
          }}
          disabled
          allowClear
          placeholder="Select..."
        />
        <AtiraIcon icon={faArrowRightArrowLeft} />
        <DropDown
          title={`Corresponding Field for Email`}
          containerStyle={{ width: '18rem' }}
          onChange={() => {}}
          defaultValue={{
            label: email.label,
            value: email.label,
          }}
          disabled
        />
      </Flex>

      {fields.map((f) => (
        <Flex key={f.label} gap="xl" alignItems="center">
          <DropDown
            title={f.label || f.placeholder || f.type}
            containerStyle={{ width: '10rem' }}
            onChange={() => {}}
            defaultValue={{
              label: f.label,
              value: f.label,
            }}
            disabled
            allowClear
            placeholder="Select..."
          />
          <AtiraIcon icon={faArrowRightArrowLeft} />
          <DropDown
            title={`Corresponding Field for ${f.label}`}
            containerStyle={{ width: '18rem' }}
            onChange={(fieldName) => onIntegrateInput(f, fieldName)}
            options={userAvailableFields.map((_f) => ({
              label: _f.field,
              value: _f.field,
            }))}
            defaultValue={{
              label: userAvailableFields[0].field,
              value: userAvailableFields[0].field,
            }}
          />
        </Flex>
      ))}
    </Flex>
  );
};
