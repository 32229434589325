import { faDollarSign } from '@fortawesome/free-solid-svg-icons/faDollarSign';
import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { AtiraDatePicker } from '../../../components/AtiraDatePicker';
import { Button } from '../../../components/Button';
import { Drawer } from '../../../components/Drawer';
import { DropDown } from '../../../components/DropDown';
import { DropDownRemoteSearch } from '../../../components/DropDownRemoteSearch';
import { Flex } from '../../../components/Flex';
import { Input } from '../../../components/Input';
import i18n, { AppLangs } from '../../../i18n';
import { Entry } from '../../../model/entry/Entry';
import { KanbanColumn } from '../../../model/kanban/KanbanColumn';
import { CreateKanbanCardDto } from '../../../model/kanban/dto/CreateKanbanCardDto';
import { entrySliceSelectors } from '../../../redux/entry/entry.selector';
import { entryActions } from '../../../redux/entry/entry.slice';
import { kanbanSliceSelectors } from '../../../redux/kanban/kanban.selector';
import { kanbanActions } from '../../../redux/kanban/kanban.slice';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { userSliceSelectors } from '../../../redux/user/user.selector';
import { AtiraToast } from '../../../utils/AtiraToast';

const StyledDropdown = styled(DropDown)`
  height: 2.3rem;
`;

const StyledSearchDropDown = styled(DropDownRemoteSearch)`
  height: 2.3rem;
`;

const StyledInput = styled(Input)`
  height: 2.3rem;

  &::placeholder {
    font-size: 1.5rem;
  }
`;

const SubmitButton = styled(Button)`
  height: 2.3rem;
  width: 50%;
`;

type KanbanCardCreateDrawerProps = {
  isOpen: boolean;
  onClose: VoidFunction;
  kanbanColumn?: KanbanColumn | null;
  entry?: Entry | null;
};

export const KanbanCardCreateDrawer: React.FC<KanbanCardCreateDrawerProps> = ({
  isOpen = false,
  onClose,
  kanbanColumn,
  entry,
}) => {
  const [kanbanCardCreateLoading, setKanbanCardCreateLoading] = useState(false);

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const drawerPlacement = i18n.language === AppLangs.AR ? 'left' : 'right';
  const searchDropdownDefaultValue = entry
    ? {
        label:
          entry.fields.name ||
          entry.fields.email ||
          entry.fields?.message ||
          '',
        value: entry._id,
      }
    : undefined;

  const userKanban = useAppSelector(
    kanbanSliceSelectors.selectUserDefaultKanban,
  );
  const userId = useAppSelector(userSliceSelectors.selectLoggedInUserId)!;
  const loggedInUserId = useAppSelector(
    userSliceSelectors.selectLoggedInUserId,
  );
  const legibleEntries = useAppSelector(
    entrySliceSelectors.selectEntriesLegibleForKanbanCard,
  );

  const { control, handleSubmit, getValues, setValue, reset, watch } =
    useForm<CreateKanbanCardDto>({
      defaultValues: {
        amount: '0',
        closingDate: undefined,
        columnId: kanbanColumn?._id,
        entryId: entry?._id,
        kanbanId: userKanban?._id,
        note: '',
        title: '',
        userId,
      },
    });

  const values = watch();

  const onCreateKanbanCrad = async () => {
    try {
      setKanbanCardCreateLoading(true);

      const dto = getValues();

      const payload = omitBy(
        {
          userId: loggedInUserId!,
          entryId: dto.entryId,
          kanbanId: userKanban?._id!,
          columnId: dto.columnId,
          order: 0,
          title: dto.title,
          closingDate: dto.closingDate
            ? new Date(dto.closingDate).getTime()
            : null,
          amount: dto.amount,
          note: dto.note || undefined,
        },
        isNil,
      ) as unknown as CreateKanbanCardDto;

      await dispatch(kanbanActions.createKanbanCard(payload)).unwrap();
      await dispatch(
        kanbanActions.getUserDefaultKanban({ userId: loggedInUserId! }),
      ).unwrap();

      localOnClose();
      AtiraToast.success(t('deals.drawer.create.success'));
    } catch (e: any) {
      AtiraToast.apiError(e);
      console.log(e);
    } finally {
      setKanbanCardCreateLoading(false);
    }
  };

  const localOnClose = () => {
    onClose();
    reset();
  };

  const onFetchEntries = async (keyword: string) => {
    try {
      const entries = await dispatch(
        entryActions.getEntriesLegibleForKanbanCard({
          keyword,
          userId,
        }),
      ).unwrap();

      return transformEntries(entries || []);
    } catch (e: any) {
      AtiraToast.apiError(e);
      console.log(e);
      return [];
    }
  };

  const transformEntries = (entries: Entry[]) => {
    return entries.map((entry) => ({
      key: entry._id,
      label:
        entry.fields.name || entry.fields.email || entry.fields?.message || '',
      value: entry._id,
    }));
  };

  useEffect(() => {
    if (isOpen) {
      reset({
        amount: '0',
        kanbanId: userKanban?._id,
        columnId:
          kanbanColumn?._id ||
          userKanban?.columns?.find((c) => c.order === 0)?._id,
      });
      dispatch(entryActions.getEntriesLegibleForKanbanCard({ userId }));
    }
  }, [
    dispatch,
    isOpen,
    kanbanColumn?._id,
    reset,
    userId,
    userKanban?._id,
    userKanban?.columns,
  ]);

  const getIsButtonActive = () => {
    if (!values.title || !values.entryId || !values.columnId) {
      return true;
    }

    return false;
  };

  const amountFocusBlur = (
    value: string | undefined,
    onChange: (val: string) => void,
    eventType: 'focus' | 'blur',
  ) => {
    if (eventType === 'focus' && (value === '0' || value === undefined)) {
      onChange('');
    } else if (eventType === 'blur' && (!value || value === '')) {
      onChange('0');
    }
  };

  useEffect(() => {
    if (entry && isOpen) {
      setValue('entryId', entry._id);
    }
  }, [entry, isOpen, setValue]);

  return (
    <Drawer
      destroyOnClose
      title={t('deals.drawer.create.title')}
      open={isOpen}
      onClose={localOnClose}
      placement={drawerPlacement}
    >
      <Flex flexDirection="column" gap="m">
        <Controller
          name="title"
          control={control}
          render={({ field: { value, onChange } }) => (
            <StyledInput
              required
              value={value}
              onChange={onChange}
              title={t('deals.drawer.input.deal_name')}
            />
          )}
        />

        <StyledSearchDropDown
          fetchOptions={onFetchEntries}
          onChange={(e: any) => setValue('entryId', e.value)}
          title={t('deals.drawer.input.contact_name')}
          placeholder={t('deals.drawer.input.contact_name_placeholder')}
          defaultOptions={transformEntries(legibleEntries)}
          defaultValue={searchDropdownDefaultValue}
        />

        <Controller
          name="columnId"
          control={control}
          render={({ field: { onChange, value } }) => (
            <StyledDropdown
              value={value}
              options={userKanban?.columns?.map(({ _id, name }) => ({
                label: name,
                value: _id,
              }))}
              onChange={onChange}
              title={t('common.stage')}
            />
          )}
        />

        <Flex position="relative">
          <Controller
            name="amount"
            control={control}
            render={({ field: { value, onChange } }) => (
              <StyledInput
                value={value}
                onChange={onChange}
                onFocus={() => amountFocusBlur(value, onChange, 'focus')}
                onBlur={() => amountFocusBlur(value, onChange, 'blur')}
                title={t('common.amount')}
                icon={faDollarSign}
              />
            )}
          />
        </Flex>

        <Controller
          name="closingDate"
          control={control}
          render={({ field: { value, onChange } }) => {
            return (
              <Flex gap="s" flexDirection="column">
                <AtiraDatePicker
                  onChange={(date) => onChange(date)}
                  type="date"
                  value={value || null}
                  format={'DD/MM/YYYY'}
                  id="date"
                  title={t('deals.drawer.input.closing_date')}
                />
              </Flex>
            );
          }}
        />

        <Controller
          name="note"
          control={control}
          render={({ field: { value, onChange } }) => (
            <StyledInput
              value={value}
              onChange={onChange}
              title={t('common.note')}
              type="text"
            />
          )}
        />

        <SubmitButton
          onClick={handleSubmit(onCreateKanbanCrad)}
          loading={kanbanCardCreateLoading}
          disabled={getIsButtonActive()}
        >
          {t('common.create')}
        </SubmitButton>
      </Flex>
    </Drawer>
  );
};
