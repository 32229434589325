import { faArrowLeft } from '@fortawesome/free-solid-svg-icons/faArrowLeft';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons/faArrowRight';
import { StepsProps } from 'antd';
import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { AtiraStepper } from '../../components/AtiraStepper';
import { Flex } from '../../components/Flex';
import { StepperStep } from '../../components/StepperStep';
import { SubHeader } from '../../components/SubHeader';
import i18n from '../../i18n';
import { CreateFormDto } from '../../model/form/dto/CreateFormDto';
import { EntryFieldsDefaults } from '../../model/form/types/EntryFieldsDefaults.enum';
import { FormPostSubmit } from '../../model/form/types/FormPostSubmit.enum';
import { FormTheme } from '../../model/form/types/FormThemes.enum';
import { Direction } from '../../model/shared/Direction.enum';
import { entrySliceSelectors } from '../../redux/entry/entry.selector';
import { useAppSelector } from '../../redux/store';
import { FormCreateIntegrateCustomFields } from './FormCreateIntegrateCustomFields';
import { FormCreateStepBasicInfo } from './FormCreateStepBasicInfo';
import { FormCreateStepFormBuilder } from './FormCreateStepFormBuilder';
import { FormCreateStepPostSubmitType } from './FormCreateStepPostSubmitType';
import { FormCreateStepTheme } from './FormCreateStepTheme';
import { canCreateForm, getFormDefaultInputs } from './FormUtils';

export const FormCreate: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const websiteLanguage = i18n.language;
  const state = useAppSelector((s) => s);
  const tableSettings = useAppSelector(
    entrySliceSelectors.selectUserEntryTableSettings,
  )!;

  const formMethods = useForm<CreateFormDto>({
    mode: 'onChange',
    defaultValues: {
      inputs: getFormDefaultInputs(tableSettings)
        .filter(({ label }) => label === EntryFieldsDefaults.EMAIL)
        .map((x) => ({ ...x, order: 0 })),
      postSubmit: FormPostSubmit.redirect,
      domains: [{ content: '' }],
      theme: FormTheme.WHITE,
      title: '',
      formDirection: Direction.LTR,
    },
  });
  const title = formMethods.watch('title');

  useEffect(() => {
    if (!canCreateForm(state)) {
      navigate('/forms', { replace: true });
    }
  }, [navigate, state]);

  // Use below as a reference to disable steps
  const stepperItemsDisabled = [!title?.trim(), false, false];
  const stepperItems: StepsProps['items'] = [
    {
      title: t('forms.create.step.1'),
      status: !title?.trim() ? 'error' : 'finish',
    },
    {
      title: t('forms.create.step.2'),
      disabled: stepperItemsDisabled[0],
    },
    {
      title: t('forms.create.step.3'),
      disabled: stepperItemsDisabled[0] || stepperItemsDisabled[1],
    },
    {
      title: t('forms.create.step.4'),
      disabled:
        stepperItemsDisabled[0] ||
        stepperItemsDisabled[1] ||
        stepperItemsDisabled[2],
    },
    {
      title: t('forms.create.step.5'),
      disabled:
        stepperItemsDisabled[0] ||
        stepperItemsDisabled[1] ||
        stepperItemsDisabled[2] ||
        stepperItemsDisabled[3],
    },
  ];

  return (
    <FormProvider {...formMethods}>
      <Flex width={'100%'} flexDirection="column" flex={1}>
        <SubHeader
          title={`${t('common.forms')} / ${t('common.create')}`}
          icon={websiteLanguage === 'ar' ? faArrowRight : faArrowLeft}
          buttonTitle={t('common.back')}
          onClick={() => navigate('/forms')}
        />
        <AtiraStepper
          components={[
            <StepperStep
              title={t('forms.create.step.1')}
              children={<FormCreateStepBasicInfo />}
            />,
            <StepperStep
              title={t('forms.create.step.2')}
              children={<FormCreateStepFormBuilder />}
            />,
            <StepperStep
              title={t('forms.create.step.3')}
              children={<FormCreateStepPostSubmitType />}
            />,
            <StepperStep
              title={t('forms.create.step.4')}
              children={<FormCreateIntegrateCustomFields />}
            />,
            <StepperStep
              title={t('forms.create.step.5')}
              children={<FormCreateStepTheme />}
            />,
          ]}
          items={stepperItems}
        />
      </Flex>
    </FormProvider>
  );
};
