import { EntriesTableSettings } from '../../model/entries-table-settings/EntriesTableSettings';
import { CreateFormDto } from '../../model/form/dto/CreateFormDto';
import { EntryFieldsDefaults } from '../../model/form/types/EntryFieldsDefaults.enum';
import { FormLimitByUserKind } from '../../model/form/types/FormLimitByUserKind';
import { InputTypes } from '../../model/form/types/InputTypes.enum';
import { UserKind } from '../../model/user/types/UserKind.enum';
import { RootState } from '../../redux/store';

export function canCreateForm(state: RootState): boolean {
  const user = state.user.loggedInUser;
  const forms = state.form.forms?.filter((f) => f.orphan !== true);

  if (!user || !forms) {
    return false;
  }

  if (
    (user.kind === UserKind.FREE &&
      forms.length >= FormLimitByUserKind[user.kind]) ||
    (user.kind === UserKind.PAID &&
      forms.length >= FormLimitByUserKind[user.kind])
  ) {
    return false;
  }

  return true;
}

function fieldToType(field: string) {
  switch (field) {
    case 'email':
      return InputTypes.EMAIL;
    case 'phone':
      return InputTypes.PHONE;
    case 'message':
      return InputTypes.TEXTAREA;
    case 'name':
    default:
      return InputTypes.TEXT;
  }
}

export function getFormDefaultInputs(
  entriesTableSetting: EntriesTableSettings,
): CreateFormDto['inputs'] {
  const createableColumns = entriesTableSetting.columns.filter((c) =>
    Object.values(EntryFieldsDefaults).includes(c.field as EntryFieldsDefaults),
  );

  return createableColumns.map((c) => ({
    type: fieldToType(c.field),
    label: c.field,
    order: c.order,
    required: false,
    custom: false,
    name: c.field,
  }));
}
