import {
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  UniqueIdentifier,
} from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faDAndD } from '@fortawesome/free-brands-svg-icons/faDAndD';
import { faCalendar } from '@fortawesome/free-solid-svg-icons/faCalendar';
import { faPhone } from '@fortawesome/free-solid-svg-icons/faPhone';
import { faTextHeight } from '@fortawesome/free-solid-svg-icons/faTextHeight';
import { faTextWidth } from '@fortawesome/free-solid-svg-icons/faTextWidth';
import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash';
import { faVoicemail } from '@fortawesome/free-solid-svg-icons/faVoicemail';
import { Row } from 'antd';
import clone from 'lodash/clone';
import _remove from 'lodash/remove';
import React, { useState } from 'react';
import {
  Controller,
  FieldArrayWithId,
  useFieldArray,
  useFormContext,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { AtiraIcon } from '../../components/AtiraIcon';
import { Clickable } from '../../components/Clickable';
import { Flex } from '../../components/Flex';
import { Input } from '../../components/Input';
import { Text } from '../../components/Text';
import { Textarea } from '../../components/Textarea';
import { DNDContext } from '../../components/dnd/DNDContext';
import { DNDSortableDroppableContext } from '../../components/dnd/DNDSortableDroppableContext';
import { SortableChild } from '../../components/dnd/SortableChild';
import i18n, { AppLangs } from '../../i18n';
import { CreateFormDto } from '../../model/form/dto/CreateFormDto';
import { EntryFieldsDefaults } from '../../model/form/types/EntryFieldsDefaults.enum';
import { InputTypes } from '../../model/form/types/InputTypes.enum';
import { Lengths } from '../../model/shared/enum/Lengths.enum';
import { entrySliceSelectors } from '../../redux/entry/entry.selector';
import { useAppSelector } from '../../redux/store';
import { Rounded } from '../../theme/Rounded';
import { Spacing } from '../../theme/Spacing';
import { AtiraColumn } from '../entries/components/AtiraColumn';
import { getFormDefaultInputs } from './FormUtils';
import { InputBox } from './components/InputBox';

const dragIconStyle = {
  right: i18n.language === AppLangs.AR ? 'auto' : '-0.5rem',
  left: i18n.language === AppLangs.AR ? '0' : 'auto',
  bottom: '2rem',
  transform: 'translateY(40%)',
};

const FormContainer = styled(Flex)`
  flex-direction: column;
  align-items: center;
  padding: ${Spacing.l};
  border-radius: ${Rounded.md};
  gap: ${Spacing.l};
  background-color: ${({ theme }) => theme.sub};
  border: 1px dotted ${({ theme }) => theme.darkerSub};
  overflow: scroll;
  width: 100%;
  min-height: 24rem;
`;

const DroppedInputsWrapper = styled(Flex)`
  width: 22rem;
  flex-direction: column;
  gap: ${Spacing.m};
  border-radius: ${Rounded.md};

  @media (min-width: 992px) {
    width: 28rem;
  }
`;

const FormTitleInput = styled(Input)`
  border: 0 !important;
  font-size: 1.75rem;
  font-weight: bold;
  padding: 0 !important;
  text-align: center;
  background-color: ${({ theme }) => theme.transparent};
  border: 1px solid ${({ theme }) => theme.transparent} !important;
  transition: all 0.3s;
  width: fit-content !important;
  margin: auto;
  text-decoration: underline;
  text-decoration-style: dotted;

  &:focus {
    border: 1px solid ${({ theme }) => theme.black} !important;
  }
`;

const StyledInput = styled(Input)`
  border: 1px solid ${({ theme }) => theme.darkerSub};
  border-radius: ${Rounded.sm};
  height: 2.5rem;
  margin-bottom: ${Spacing.m};
  width: 19rem !important;

  @media (min-width: 992px) {
    width: 25rem !important;
  }
`;

const InputLabel = styled(Input)<{ isCustom: boolean }>`
  border: 1px solid ${({ theme }) => theme.transparent} !important;
  width: fit-content !important;
  padding: 0.2rem !important;
  background-color: ${({ theme }) => theme.transparent};
  border: 1px solid ${({ theme }) => theme.transparent} !important;
  transition: all 0.3s;
  margin-inline-start: ${({ isCustom }) => (isCustom ? '0.1rem' : Spacing.xl)};

  &:focus {
    border: 1px solid ${({ theme }) => theme.black} !important;
  }
`;

const StyledTextArea = styled(Textarea)`
  border-radius: ${Rounded.sm};
  margin-bottom: ${Spacing.m};
  width: 19rem !important;

  @media (min-width: 992px) {
    width: 25rem !important;
  }
`;

const StyledRow = styled(Row)`
  width: 100%;
`;

const StyledClickable = styled(Clickable)`
  margin-top: -0.7rem;
`;

type DefaultInput = Omit<CreateFormDto['inputs'][number], 'order'> & {
  icon: IconProp;
};

const ExtraInputs: DefaultInput[] = [
  {
    type: InputTypes.TEXT,
    label: '',
    placeholder: '',
    required: true,
    icon: faTextHeight,
    name: '',
  },
  {
    type: InputTypes.PHONE,
    label: '',
    placeholder: '',
    required: true,
    icon: faPhone,
    name: '',
  },
  {
    label: '',
    placeholder: '',
    required: true,
    type: InputTypes.TEXTAREA,
    icon: faTextWidth,
    name: '',
  },
  {
    label: '',
    placeholder: '',
    required: true,
    type: InputTypes.DATE,
    icon: faCalendar,
    name: '',
  },
  {
    label: '',
    placeholder: '',
    required: true,
    type: InputTypes.EMAIL,
    icon: faVoicemail,
    name: '',
  },
];

export const FormCreateStepFormBuilder: React.FC = () => {
  const { control, getValues, watch } = useFormContext<CreateFormDto>();

  const { append, remove, fields, replace } = useFieldArray({
    control,
    name: 'inputs',
  });

  const [activeItemId, setActiveItemId] = useState<
    UniqueIdentifier | undefined
  >();

  const { t } = useTranslation();
  const tableSettings = useAppSelector(
    entrySliceSelectors.selectUserEntryTableSettings,
  )!;

  const getBasicInputIcon = (inputType: InputTypes) => {
    switch (inputType) {
      case InputTypes.PHONE:
        return faPhone;

      case InputTypes.TEXTAREA:
        return faTextHeight;
      default:
        return faTextWidth;
    }
  };

  const [defaultInputs, setDefaultInputs] = useState<DefaultInput[]>(
    getFormDefaultInputs(tableSettings)
      .filter(({ label }) => label !== EntryFieldsDefaults.EMAIL)
      .map((x) => ({ ...x, icon: getBasicInputIcon(x.type) })),
  );

  const onDragStart = (event: DragStartEvent) => {
    const { active } = event;
    setActiveItemId(active.id);
  };

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (!over || active.id === over.id) {
      return;
    }

    const activeIndex = fields.findIndex((item) => item.id === active.id);
    const overIndex = fields.findIndex((item) => item.id === over.id);

    const oldInputs = getValues('inputs');
    const newInputs = arrayMove(clone(fields), activeIndex, overIndex).map(
      (item, index) => ({
        ...item,
        order: index,
        label:
          oldInputs.find((inp: any) => inp.id === item.id)?.label || item.label,
        placeholder:
          oldInputs.find((inp: any) => inp.id === item.id)?.placeholder ||
          item.placeholder,
      }),
    );

    replace(newInputs);

    setActiveItemId(undefined);
  };

  const onRemoveInput = (
    index: number,
    input: FieldArrayWithId<CreateFormDto, 'inputs', 'id'>,
  ) => {
    remove(index);

    if ((input as any).custom === false) {
      setDefaultInputs([...defaultInputs, { ...input, icon: faDAndD }]);
    }
  };
  const onAddDefaultInput = (i: number, x: DefaultInput) => {
    append({ ...x, order: fields.length });
    setDefaultInputs(_remove(defaultInputs, (n, index) => index !== i));
  };

  const onAddExtraInput = (x: DefaultInput) =>
    append({ ...x, order: fields.length, custom: true } as any);

  const renderDragOverlay = () => {
    if (!activeItemId) {
      return null;
    }

    const activeItem = fields.find((item) => item.id === activeItemId);
    const currentInputIndex = fields.findIndex((x) => x.id === activeItemId);

    const currentInput = watch('inputs')?.[currentInputIndex];

    if (!activeItem) {
      return null;
    }

    return (
      <InputBox
        icon={(currentInput as any).icon}
        inputName={t(`common.${(currentInput as any)?.type.toLowerCase()}`)}
        pointerEvents="none"
      />
    );
  };

  return (
    <StyledRow gutter={10}>
      <AtiraColumn xs={8} sm={8} md={8} lg={5} xl={5}>
        <Row gutter={[0, 40]}>
          <AtiraColumn md={24} lg={24} xl={24}>
            <Flex flexDirection="column" gap="m">
              <Text fontSize="xm" fontWeight={'bold'}>
                {t('forms.create.step.2.default_inputs.title')}
              </Text>

              <Row gutter={[10, 10]}>
                {defaultInputs.map((x, i) => (
                  <AtiraColumn md={12} lg={12} xl={12} key={x.label}>
                    <InputBox
                      icon={x.icon}
                      inputName={t(`common.${x.type.toLowerCase()}`)}
                      onClick={() => onAddDefaultInput(i, x)}
                    />
                  </AtiraColumn>
                ))}
              </Row>
            </Flex>
          </AtiraColumn>

          <AtiraColumn md={24} lg={24} xl={24}>
            <Flex flexDirection="column" gap="m">
              <Text fontWeight={'bold'}>
                {t('forms.create.step.2.extra_inputs.title')}
              </Text>

              <Row gutter={[10, 10]}>
                {ExtraInputs.map((x) => (
                  <AtiraColumn md={12} lg={12} xl={12} key={x.label}>
                    <InputBox
                      icon={x.icon}
                      inputName={t(`common.${x.type.toLowerCase()}`)}
                      onClick={() => onAddExtraInput(x)}
                    />
                  </AtiraColumn>
                ))}
              </Row>
            </Flex>
          </AtiraColumn>
        </Row>
      </AtiraColumn>

      <AtiraColumn xs={16} sm={16} md={16} lg={19} xl={19}>
        <FormContainer>
          <FormTitleInput placeholder={t('forms.create.step.2.form_title')} />

          <DNDContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
            <DroppedInputsWrapper>
              <DNDSortableDroppableContext id="dropZone" items={fields}>
                {fields.map((input, index) => (
                  <Flex
                    justifyContent="center"
                    gap="m"
                    alignItems="center"
                    key={input.id}
                  >
                    <SortableChild
                      id={input.id}
                      showDragIcon
                      dragIconStyle={dragIconStyle}
                      iconSize="2x"
                    >
                      <Flex flexDirection="column">
                        <Controller
                          control={control}
                          name={`inputs.${index}.label` as const}
                          rules={{
                            required: true,
                            maxLength: {
                              message: `Max length is ${Lengths.NAME}`,
                              value: Lengths.NAME,
                            },
                          }}
                          render={({ field: { value, onChange } }) => (
                            <Flex gap="s">
                              <InputLabel
                                isCustom={
                                  (input as any).custom === false &&
                                  input.type === InputTypes.EMAIL
                                }
                                value={value}
                                onChange={onChange}
                                placeholder={t(
                                  `forms.create.step.2.${input.type?.toLowerCase()}.label`,
                                )}
                              />
                            </Flex>
                          )}
                        />

                        <Flex alignItems="center" gap="s">
                          {(input as any).basic === true &&
                          input.type === InputTypes.EMAIL ? null : (
                            <StyledClickable
                              onClick={() => onRemoveInput(index, input)}
                            >
                              <AtiraIcon
                                icon={faTrash}
                                color="main"
                                size="lg"
                              />
                            </StyledClickable>
                          )}

                          <Controller
                            control={control}
                            name={`inputs.${index}.placeholder` as const}
                            render={({ field: { value, onChange } }) =>
                              input.type === InputTypes.TEXTAREA ? (
                                <StyledTextArea
                                  value={value}
                                  onChange={onChange}
                                  placeholder={t(
                                    `forms.create.step.2.${input.type.toLowerCase()}.placeholder`,
                                  )}
                                  type={input.type.toLowerCase()}
                                />
                              ) : (
                                <StyledInput
                                  value={value}
                                  onChange={onChange}
                                  placeholder={t(
                                    `forms.create.step.2.${input.type?.toLowerCase()}.placeholder`,
                                  )}
                                  type={input.type?.toLowerCase()}
                                />
                              )
                            }
                          />
                        </Flex>
                      </Flex>
                    </SortableChild>
                  </Flex>
                ))}
              </DNDSortableDroppableContext>

              <DragOverlay
                dropAnimation={{
                  duration: 500,
                  easing: 'cubic-bezier(0.18, 0.67, 0.6, 1.22)',
                }}
              >
                {renderDragOverlay()}
              </DragOverlay>
            </DroppedInputsWrapper>
          </DNDContext>
        </FormContainer>
      </AtiraColumn>
    </StyledRow>
  );
};
