import React from 'react';
import { useTranslation } from 'react-i18next';

import { Flex } from '../../../components/Flex';
import { Text } from '../../../components/Text';

export const EntriesExportTab: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Flex flexDirection="column" gap="m">
      <Text color="darkerSub">{t('entries.export.description')}</Text>

      <Text>{t('common.feature.coming_soon')}</Text>
    </Flex>
  );
};
