import { UploadFile } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AtiraUpload } from '../../../components/AtiraUpload';
import { Flex } from '../../../components/Flex';
import { Text } from '../../../components/Text';

export const EntriesImportTab: React.FC = () => {
  const { t } = useTranslation();

  const [uploadedFile, setUploadedFile] = useState<UploadFile | null>(null);

  return (
    <Flex flexDirection="column" gap="m">
      <Text color="darkerSub">{t('entries.import.description')}</Text>

      <AtiraUpload
        dragHover
        title={t('entries.import.upload.title')}
        onChange={(e) => setUploadedFile(e)}
      />
    </Flex>
  );
};
