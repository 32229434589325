import { Radio } from 'antd';
import { TFunction } from 'i18next';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Flex } from '../../components/Flex';
import { Input } from '../../components/Input';
import { Text } from '../../components/Text';
import i18n, { AppLangs } from '../../i18n';
import { CreateFormDto } from '../../model/form/dto/CreateFormDto';
import { FormPostSubmit } from '../../model/form/types/FormPostSubmit.enum';
import { Direction } from '../../model/shared/Direction.enum';
import { Breakpoints } from '../../theme/Breakpoints';
import { Rounded } from '../../theme/Rounded';
import { Spacing } from '../../theme/Spacing';
import { Regexes, isValidUrl } from '../../utils/String';

const LeftSideWrapper = styled(Flex)`
  width: 100%;
  flex-direction: column;
  padding-bottom: ${Spacing.l};
  border-bottom: 2px solid ${({ theme }) => theme.darkerSub};
  gap: ${Spacing.m};

  @media (min-width: ${Breakpoints.DESKTOP}) {
    border-right: ${({ theme }) =>
      i18n.language === AppLangs.AR ? '0' : `2px solid ${theme.darkerSub}`};
    border-left: ${({ theme }) =>
      i18n.language === AppLangs.AR ? `2px solid ${theme.darkerSub}` : '0'};
    padding-right: ${Spacing.xl};
    padding-bottom: 0;
    border-bottom: 0;
    width: 63%;
    justify-content: center;
    height: 100%;
  }
`;

const RightSideWrapper = styled(Flex)`
  width: 100%;
  flex-direction: column;
  gap: ${Spacing.m};

  @media (min-width: ${Breakpoints.DESKTOP}) {
    width: 35%;
  }
`;

const StyledInput = styled(Input)`
  width: 100%;
  height: 2.5rem;
  padding: 0.5rem 1rem;
  border: 1px solid ${({ theme }) => theme.darkSub};
  font-size: 1.2rem;
`;

const InputWrapper = styled(Flex)`
  padding: ${Spacing.m};
  border: 2px solid ${({ theme }) => theme.darkerSub};
  border-radius: ${Rounded.md};
  margin-left: ${Spacing.l};
`;

const StyledRadio = styled(Radio)`
  font-size: 1.1rem;
`;

const StyledSpan = styled.span`
  font-weight: 600;
  font-size: 1.1rem;
  color: ${({ theme }) => theme.textColor};
`;

const DescriptionSpan = styled.span`
  color: ${({ theme }) => theme.darkerSub};
`;

export const FormCreateStepPostSubmitType: React.FC = () => {
  const { watch, control } = useFormContext<CreateFormDto>();

  const { t } = useTranslation();

  const postSubmit = watch('postSubmit');

  return (
    <Flex flexDirection="column">
      <Flex gap={'m'} alignItems="center" width={'100%'} flexWrap="wrap">
        <LeftSideWrapper>
          <Controller
            control={control}
            name="postSubmit"
            render={({ field: { onChange, value } }) => (
              <StyledRadio
                onChange={() => onChange(FormPostSubmit.confirm)}
                checked={value === FormPostSubmit.confirm}
                value={FormPostSubmit.confirm}
              >
                {t('forms.create.step.3.radio.confirmation')}
              </StyledRadio>
            )}
          />

          <InputWrapper>
            <Controller
              control={control}
              name="postSubmitMessage"
              rules={{
                required: postSubmit === FormPostSubmit.confirm,
                maxLength: 280,
              }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <StyledInput
                  value={value}
                  onChange={onChange}
                  title={t('forms.create.step.3.input.confirmation.label')}
                  placeholder={`${t('common.example')}: ${t('common.thank_you')}`}
                  valid={!error}
                  errorMessage={error?.message}
                  maxLength={280}
                />
              )}
            />
          </InputWrapper>

          <Controller
            control={control}
            name="postSubmit"
            render={({ field: { onChange, value } }) => (
              <StyledRadio
                checked={value === FormPostSubmit.redirect}
                onChange={() => onChange(FormPostSubmit.redirect)}
                value={FormPostSubmit.redirect}
              >
                {t('forms.create.step.3.radio.redirect')}
              </StyledRadio>
            )}
          />

          <InputWrapper>
            <Controller
              control={control}
              name="postSubmitUrl"
              rules={{
                required: postSubmit === FormPostSubmit.redirect,
                pattern: {
                  value: Regexes.URL_REGEX,
                  message: t('forms.create.step.3.error.invalid_url'),
                },
              }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <StyledInput
                  value={value}
                  onChange={onChange}
                  title={t('forms.create.step.3.input.label.url')}
                  placeholder={`${t('common.example')}: www.example.com/thanks`}
                  valid={!error}
                  errorMessage={error?.message}
                  required
                />
              )}
            />
          </InputWrapper>
        </LeftSideWrapper>

        <RightSideWrapper>
          <Text>
            <StyledSpan>
              {`${t('forms.create.step.3.radio.confirmation')}: `}
            </StyledSpan>

            <DescriptionSpan>
              {t('forms.create.step.3.radio.confirm.description')}
            </DescriptionSpan>
          </Text>

          <Text>
            <StyledSpan>{t('forms.create.step.3.radio.redirect')}: </StyledSpan>

            <DescriptionSpan>
              {t('forms.create.step.3.radio.redirect.description')}
            </DescriptionSpan>
          </Text>
        </RightSideWrapper>
      </Flex>

      <Flex gap={'m'} marginTop="xxl" alignItems="center" flexWrap="wrap">
        <LeftSideWrapper>
          <Controller
            control={control}
            name="formDirection"
            render={({ field: { value, onChange } }) => (
              <Radio.Group onChange={onChange} value={value}>
                <Flex flexDirection="column" gap="l">
                  <Text fontWeight={'bold'} fontSize="l">
                    {t('form.direction')}
                  </Text>

                  <StyledRadio value={Direction.LTR}>
                    {t('common.ltr')}
                  </StyledRadio>
                  <StyledRadio value={Direction.RTL}>
                    {t('common.rtl')}
                  </StyledRadio>
                </Flex>
              </Radio.Group>
            )}
          />
        </LeftSideWrapper>

        <RightSideWrapper>
          <Text>
            <StyledSpan>{t('common.ltr')}: </StyledSpan>

            <DescriptionSpan>
              {t('form.create.ltr.description')}
            </DescriptionSpan>
          </Text>

          <Text>
            <StyledSpan>{t('common.rtl')}: </StyledSpan>

            <DescriptionSpan>
              {t('form.create.rtl.description')}
            </DescriptionSpan>
          </Text>
        </RightSideWrapper>
      </Flex>
    </Flex>
  );
};
