import { faCopy } from '@fortawesome/free-solid-svg-icons/faCopy';
import { Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { AtiraIcon } from '../../../components/AtiraIcon';
import { Button } from '../../../components/Button';
import { Flex } from '../../../components/Flex';
import { Text } from '../../../components/Text';
import { Modal } from '../../../components/modal/Modal';
import { formSliceSelectors } from '../../../redux/forms/form.selector';
import { useAppSelector } from '../../../redux/store';
import { Rounded } from '../../../theme/Rounded';
import { AtiraToast } from '../../../utils/AtiraToast';

const StyledFlex = styled(Flex)`
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
`;

const StyledCode = styled.code`
  display: inline-block;
  overflow: auto;
  background: ${({ theme }) => theme.lightgray};
  padding: 0.5rem 0.2rem;
  border-radius: ${Rounded.sm};
  font-size: 0.75rem;
  width: 25rem;
  font-weight: bolder;
  direction: ltr;
`;

const StyledDirectLinkText = styled(Text)`
  word-break: break-word;
  background: ${({ theme }) => theme.lightgray};
  padding: 0.5rem 0.2rem;
  border-radius: ${Rounded.sm};
  width: 25rem;
  margin: 0;
  font-size: 0.9rem;
  font-weight: bold;
  direction: ltr;
`;

interface FormShareModalProps {
  isOpen: boolean;
  onClose: VoidFunction;
}

export const FormShareModal: React.FC<FormShareModalProps> = ({
  isOpen,
  onClose,
}) => {
  const { t } = useTranslation();

  const currentForm = useAppSelector(formSliceSelectors.selectCurrentForm);

  const formLink = currentForm
    ? `${process.env.REACT_APP_API_URL}/form/${currentForm._id}/generate`
    : null;

  const formIframe = currentForm
    ? `<iframe width="600" height="400" style="border: none;" src='${formLink}'></iframe>`
    : null;

  const onCopy = (str: string) => {
    navigator.clipboard.writeText(str);
    AtiraToast.success(t('common.copy.success'));
  };

  if (!currentForm) {
    return null;
  }

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      title={`${t('forms.share.modal.title')}: ${currentForm.title}`}
    >
      <Flex
        width={'100%'}
        gap="l"
        flexDirection="column"
        justifyContent="center"
        alignContent="center"
      >
        <StyledFlex>
          <Text color="gray">{t('forms.share.modal.direct_link')}</Text>

          <Flex justifyContent="space-between" alignItems="center">
            <StyledDirectLinkText>{formLink}</StyledDirectLinkText>
            <Tooltip title={t('common.copy')}>
              <Button
                onClick={() => onCopy(formLink || '')}
                backgroundColor="transparent"
                margin="0"
              >
                <AtiraIcon color="gray" size="2x" icon={faCopy} />
              </Button>
            </Tooltip>
          </Flex>
        </StyledFlex>

        <StyledFlex>
          <Text color="gray">{t('forms.share.modal.iframe')}</Text>

          <Flex justifyContent="space-between" alignItems="center">
            <StyledCode>{formIframe}</StyledCode>

            <Tooltip title={t('common.copy')}>
              <Button
                onClick={() => onCopy(formIframe!)}
                backgroundColor="transparent"
                margin="0"
              >
                <AtiraIcon color="gray" size="2x" icon={faCopy} />
              </Button>
            </Tooltip>
          </Flex>
        </StyledFlex>
      </Flex>
    </Modal>
  );
};
